<svelte:options immutable={true} />

<script>
  import { onMount, createEventDispatcher } from "svelte";
  import { clickOutside } from "./utils/clickOutside.js";
  export let items;
  export let type;
  export let placeholder;
  export let merusToken;
  export let appId;
  let searchKey = "";
  let displayDropdown = false;
  let noEmailUser;
  let addEmailPanel = false;
  let newEmail;
  let checkIfEmail;
  let searchCount = 0;
  let newPanel = false;
  let merusPanel = false;
  let name = "";
  let email = "";
  let phone = "";
  let casefileLink = "";
  let casefileId = "";
  const dispatch = createEventDispatcher();
  async function render() {
    addEmailPanel = false;
    noEmailUser = "";
  }
  function onSelectKey() {
    if (type == "email") {
      //dispatch("enterEmail", { item: { email: searchKey, id: "" } });
    }
  }
  function onSelect(item) {
    console.log("item", item);
    searchCount = 0;
    if (type == "email" || type == "case") {
      if (item.email == "") {
        addEmailPanel = true;
        noEmailUser = item;
        return false;
      }
      searchKey = item.email;
    } else {
      searchKey = item.label;
    }
    dispatch("select", { item });
  }
  function onDelete() {
    dispatch("delete");
  }
  function displayDropdownFunc() {
    displayDropdown = !displayDropdown;
  }
  function hideDropdownFunc() {
    displayDropdown = false;
  }
  async function updateSelectedUser() {
    if (newEmail != "") {
      const regexExp =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;

      checkIfEmail = regexExp.test(newEmail);
      if (checkIfEmail) {
        noEmailUser.email = newEmail;
        console.log("noEmailUser", noEmailUser);
        const jwtToken = localStorage.getItem("token");
        if (jwtToken != null) {
          await fetch("https://api.qix.cloud/conversation", {
            method: "PUT",
            body: JSON.stringify(noEmailUser),
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + jwtToken,
            },
          });
          addEmailPanel = false;
          onSelect(noEmailUser);
          noEmailUser = "";
        }
      }
    }
  }
  function incrementSearchCount() {
    searchCount++;
    //console.log("searchCount", searchCount);
    return true; // allows the condition to continue
  }
  function initSearchCount() {
    searchCount = 0;
    console.log("searchCount", searchCount);
  }
  function selectAddPanel(e) {
    casefileId = "";
    name = "";
    email = "";
    phone = "";

    if (e == "new") {
      merusPanel = false;
      newPanel = true;
    }
    if (e == "merus") {
      newPanel = false;
      merusPanel = true;
    }
  }
  function extractCaseFileId(url) {
    const match = url.match(/\/caseFiles\/view\/(\d+)/);
    return match ? match[1] : null;
  }
  async function fetchMeruscaesData() {
    if (casefileLink != "") {
      casefileId = extractCaseFileId(casefileLink);
      if (casefileId != "" && merusToken != "") {
        let checkCasefileId = true;
        items.forEach((item, index) => {
          if (item.advanced.caseFileIds.length > 0) {
            let caseFileIds = item.advanced.caseFileIds;
            caseFileIds.forEach((caseId, idx) => {
              if (caseId == casefileId) {
                checkCasefileId = false;
              }
            });
            if (!checkCasefileId) {
              onSelect(item);
              return;
            }
          }
        });
        let body = new URLSearchParams();
        body.append("action", "getCaseFileData");
        body.append("casefileId", casefileId);
        body.append("merusToken", merusToken);
        const req = await fetch("https://qix.cloud/ajax/", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: body.toString(),
        });
        const res = await req.json();
        name = res.name;
        email = res.email;
        phone = res.phone;
        merusPanel = false;
        newPanel = true;
      }
    }
  }
  function convertToInternational(phoneNumber) {
    const cleaned = phoneNumber.replace(/\D/g, ""); // Remove non-numeric characters
    if (cleaned.length > 10) {
      return `+${cleaned}`;
    } else {
      return `+1${cleaned}`;
    }
  }

  async function updateExistUser(item) {
    const jwtToken = localStorage.getItem("token");
    if (jwtToken != null) {
      await fetch("https://api.qix.cloud/conversation", {
        method: "PUT",
        body: JSON.stringify(item),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwtToken,
        },
      });
      addEmailPanel = false;
      onSelect(item);
    }
  }
  async function addNewUser() {
    if (name != "" && email != "" && phone != "") {
      let cItem = "";
      items.forEach((item, index) => {
        if (
          convertToInternational(phone) == item.phone ||
          email == item.email
        ) {
          // item.email = email;
          // item.phone = convertToInternational(phone);
          // item.fullName = name;
          // updateExistUser(item);
          cItem = item;
          return;
        }
      });
      if (cItem != "") {
        newPanel = false;
        onSelect(cItem);
        return;
      }
      const jwtToken = localStorage.getItem("token");
      if (jwtToken != null) {
        const newUser = {
          phone: convertToInternational(phone),
        };
        console.log("newUser", newUser);

        const queryString = new URLSearchParams(newUser).toString();

        const res = await fetch(
          `https://api.qix.cloud/conversation?${queryString}`,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + jwtToken,
            },
          }
        );
        const conversationData = await res.json();
        conversationData.fullName = name;
        conversationData.email = email;
        conversationData.phone = convertToInternational(phone);
        if (casefileId != "") {
          conversationData.advanced.caseFileIds.push(casefileId);
        }
        console.log("conversationData", conversationData);
        const updateResponse = await fetch(
          "https://api.qix.cloud/conversation",
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + jwtToken,
            },
            body: JSON.stringify(conversationData),
          }
        );

        if (!updateResponse.ok)
          throw new Error("Failed to update conversation data");

        const updateResult = await updateResponse.json();
        if (updateResult === "success") {
          onSelect(conversationData);
        }
      }
    }
  }
  onMount(render);
</script>

<div class="select-menu-wrap" style="min-width: 300px;">
  {#if addEmailPanel}
    <div>no email available</div>
    <input
      placeholder="Add Email"
      type="text"
      class="flex-grow bg-transparent py-1 px-2 w-full"
      style="border: 1px solid;border-radius: 5px;margin-bottom: 5px;"
      bind:value={newEmail}
    />
    <button
      class="w-20 text-white font-bold py-1 px-3 md:px-4 mr-3 md:mr-4 rounded bg-blue-500 hover:bg-blue-700"
      on:click={() => updateSelectedUser()}>Update</button
    >
  {:else}
    <div>
      <input
        {placeholder}
        type="text"
        class="flex-grow bg-transparent py-1 px-2 w-full"
        style="border: 1px solid;border-radius: 5px;"
        bind:value={searchKey}
        on:click={displayDropdownFunc}
        use:clickOutside
        on:click_outside={hideDropdownFunc}
        on:blur={onSelectKey}
        on:keyup={initSearchCount}
      />
    </div>
    <div
      class="overflow-y-auto py-1 select-menu"
      class:hidden={!displayDropdown}
    >
      {#each items as item}
        {#if type == "email" || type == "case"}
          {#if item.email != "" || item.fullName != ""}
            {#if searchKey == "" || item.email
                .toLowerCase()
                .includes(searchKey.toLowerCase()) || (item.fullName
                .toLowerCase()
                .includes(searchKey.toLowerCase()) && incrementSearchCount())}
              <div
                class="flex items-center rounded p-2 hover:bg-gray-100 dark:hover:bg-gray-600"
                on:click={() => onSelect(item)}
              >
                <span>
                  {item.fullName}<br />
                  ({item.email})<br />
                  {item.advanced.caseFileIds.length > 0
                    ? item.advanced.caseFileIds.toString()
                    : "No Case File ID"}
                </span>
                <img alt="" src="/flags/{item.language}.png" />
              </div>
            {/if}
          {/if}
        {:else if searchKey == "" || item.label
            .toLowerCase()
            .includes(searchKey.toLowerCase())}
          <div
            class="flex items-center rounded p-2 hover:bg-gray-100 dark:hover:bg-gray-600"
            on:click={() => onSelect(item)}
          >
            <span>{item.label}</span><img
              alt=""
              src="/flags/{item.language}.png"
            />
          </div>
        {/if}
      {/each}
    </div>
    {#if searchCount < 1 && searchKey}
      <div class="flex items-center px-2 py-1">
        <button
          class="flex items-center g-m-btn cursor-pointer"
          on:click={() => selectAddPanel("merus")}
        >
          <img
            alt=""
            src="meruscase.png"
            style="height: 20px; margin-right: 15px;"
          />
          Merus
        </button>
        <button
          class="flex items-center g-m-btn cursor-pointer"
          on:click={() => selectAddPanel("new")}
        >
          <img alt="" src="add.svg" style="height: 20px; margin-right: 15px;" />
          Add New
        </button>
      </div>
      {#if merusPanel}
        <div class="flex items-center px-2 py-1">
          <input
            placeholder="Insert Meruscase Link"
            type="text"
            class="flex-grow bg-transparent py-1 px-2 w-full"
            style="border: 1px solid;border-radius: 5px;margin-bottom: 5px;"
            bind:value={casefileLink}
          />
        </div>
        <div
          class="flex items-center justify-center px-2 py-1"
          style="gap: 10px;"
        >
          <button
            class="flex items-center g-m-btn cursor-pointer"
            on:click={() => fetchMeruscaesData()}
          >
            Fetch data
          </button>
        </div>
      {:else if newPanel}
        <div class="flex items-center px-2 py-1" style="gap: 10px;">
          <input
            placeholder="Insert Name"
            type="text"
            class="flex-grow bg-transparent py-1 px-2 w-full"
            style="border: 1px solid;border-radius: 5px;margin-bottom: 5px;"
            bind:value={name}
          />
          <input
            placeholder="Insert Email"
            type="text"
            class="flex-grow bg-transparent py-1 px-2 w-full"
            style="border: 1px solid;border-radius: 5px;margin-bottom: 5px;"
            bind:value={email}
          />
          <input
            placeholder="Insert Phone"
            type="text"
            class="flex-grow bg-transparent py-1 px-2 w-full"
            style="border: 1px solid;border-radius: 5px;margin-bottom: 5px;"
            bind:value={phone}
          />
        </div>
        <div
          class="flex items-center justify-center px-2 py-1"
          style="gap: 10px;"
        >
          <button
            class="flex items-center g-m-btn cursor-pointer"
            on:click={addNewUser}
          >
            Save
          </button>
        </div>
      {/if}
    {/if}
  {/if}
</div>

<style>
  .select-menu-wrap {
    position: relative;
  }
  .select-menu > div {
    justify-content: space-between;
    cursor: pointer;
  }
  .select-menu img {
    width: 20px;
  }
  .select-menu {
    max-height: 12rem;
    position: absolute;
    background: #fff;
    min-width: 100%;
  }
</style>
