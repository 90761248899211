<svelte:options immutable={true} />

<script>
  import { onMount, createEventDispatcher } from "svelte";
  import { clickOutside } from "./utils/clickOutside.js";
  import Selectbox from "./Selectbox.svelte";
  export let users;
  export let sign;
  export let blog;
  export let user;
  export let sendToId;
  export let chatUsers;
  export let merusToken = "";
  let merusTokenPanel = false;
  let caseFileId = "";
  let sendToType = "";
  let showInputCase = false;
  let step = 1;
  let selectedUserId = 0;
  let caseIds = [];
  let case_id = "";
  let selectedUser = "";
  let loading = false;
  const dispatch = createEventDispatcher();
  async function render() {}

  /*
  merusToken =
    typeof user.settings.merusCase.token === "undefined"
      ? ""
      : user.settings.merusCase.token;
  */
  //console.log("merusToken", merusToken);
  function isJSON(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }
  async function onSendToFunc(type, path) {
    let error = "";
    sendToType = type;
    if (sendToType == "merus") {
      if (merusToken == "" && caseFileId == "") {
        step = 1;
      } else if (merusToken != "" && caseFileId == "") {
        step = 2;
      }
      console.log("step", step);
      if (merusToken == "" || caseFileId == "") {
        merusTokenPanel = true;
        showInputCase = false;
        caseIds = [];
        return;
      }
      let paths = [];
      if (isJSON(path)) {
        pathData = JSON.parse(path);
        for (let i = 0; i < pathData.length; i++) {
          paths.push(pathData[i].link);
        }
      } else {
        paths.push(path);
      }
      for (let i = 0; i < paths.length; i++) {
        let body = {
          id: user.appId,
          auth: "google",
          token: merusToken,
          case: caseFileId,
          path: paths[i],
        };
        let res = await fetch(
          blog.siteurl +
            "/wp-admin/admin-ajax.php?action=uploadSignatureFromCloudFlare",
          {
            method: "POST",
            body: JSON.stringify(body),
          }
        );
        let results = await res.json();
        if (typeof results.errors !== "undefined") {
          error = results.errors[0].errorMessage;
        }
      }
    } else if (sendToType == "google") {
      let paths = [];
      if (isJSON(path)) {
        pathData = JSON.parse(path);
        for (let i = 0; i < pathData.length; i++) {
          paths.push(pathData[i].link);
        }
      } else {
        paths.push(path);
      }
      for (let i = 0; i < paths.length; i++) {
        let body = { id: user.id, path: paths[i] };
        let res = await fetch(
          blog.siteurl +
            "/wp-admin/admin-ajax.php?action=uploadSignatureToGoogleDriver",
          {
            method: "POST",
            body: JSON.stringify(body),
          }
        );
        let results = await res.json();
        if (results.status == "failed") {
          error = "Failed";
        } else {
          window.open(results.alternateLink, "_blank");
        }
      }
    }

    dispatch("update", {
      signId: sign.id,
      error: error,
    });
  }
  async function handleUserSelect(event) {
    /*
    selectedUserId = event.detail.item.value;
    let body = {
      id: selectedUserId,
      blog: event.detail.item.blog,
      merusToken: merusToken,
    };
    let res = await fetch(
      blog.siteurl + "/wp-admin/admin-ajax.php?action=getCaseFileIdsCloudflare",
      {
        method: "POST",
        body: JSON.stringify(body),
      }
    );
    caseIds = await res.json();
    */
    loading = true;
    console.log(event.detail.item);
    selectedUser = event.detail.item;
    caseIds = event.detail.item.advanced.caseFileIds;
    if (caseIds.length < 1) {
      showInputCase = true;
    } else {
      caseFileId = caseIds[0];
    }
    loading = false;
    // selectedUser = event.detail.item;
    // smsLang = smsLang == "" ? selectedUser.lang : smsLang;
    // console.log("selectedUser", selectedUser);
  }
  onMount(render);
</script>

<div class="w-full flex items-center" class:hidden={sendToId != sign.id}>
  <div
    class="flex items-center g-m-btn cursor-pointer"
    on:click={() => onSendToFunc("google", sign.signedPdf)}
    class:hidden={merusTokenPanel}
  >
    <img
      alt=""
      src="google_drive_icon.png"
      style="height: 20px; margin-right: 15px;"
    />
    <span>Google</span>
  </div>
  <div
    class="flex items-center g-m-btn cursor-pointer"
    on:click={() => onSendToFunc("merus", sign.signedPdf)}
    class:hidden={merusTokenPanel}
  >
    <img alt="" src="meruscase.png" style="height: 20px; margin-right: 15px;" />
    <span>Merus</span>
  </div>
  <div class="w-full flex meruscasePanel" class:hidden={!merusTokenPanel}>
    <!-- <div style="width: calc(100% - 100px);"> -->
    <div>
      <input
        placeholder="Meruscase Token"
        type="text"
        class="flex-grow bg-transparent py-1 px-2 w-full"
        style="border: 1px solid;border-radius: 5px;margin-right: 1em;"
        class:hidden={step != 1}
        bind:value={merusToken}
      />
      {#if step == 2}
        {#if loading}
          <img alt="" src="/loading.gif" style="max-width: 30px;" />
        {:else}
          <input
            placeholder="Case File Id"
            type="text"
            class="flex-grow bg-transparent py-1 px-2 w-full"
            style="border: 1px solid;border-radius: 5px;margin-right: 1em;"
            class:hidden={!showInputCase}
            bind:value={caseFileId}
          />
          {#if caseIds.length > 0}
            <select
              bind:value={caseFileId}
              style="border: 1px solid; border-radius: 5px; padding: 3px 5px;"
            >
              {#each caseIds as caseId}
                <option value={caseId}>{caseId}</option>
              {/each}
            </select>
          {:else if users.length > 0 && !showInputCase}
            <Selectbox
              items={chatUsers}
              type="case"
              placeholder="Select a user"
              on:select={handleUserSelect}
            ></Selectbox>
          {/if}
        {/if}
      {/if}
    </div>
    <button
      on:click={() => onSendToFunc("merus", sign.signedPdf)}
      class="whitespace-no-wrap bg-blue-500 hover:bg-blue-700 text-white
      font-bold py-1 px-3 md:px-4 rounded mr-3 cursor-pointer md:mr-4"
      style="margin-left: 1em"
    >
      Send
    </button>
  </div>
</div>

<style>
  .meruscasePanel {
    align-items: flex-start;
  }
</style>
