<script>
  import { onMount, createEventDispatcher } from "svelte";
  import {
    readAsArrayBuffer,
    readAsImage,
    readAsPDF,
    readAsDataURL,
  } from "./utils/asyncReader.js";
  import { save, getData, getPdfPageData } from "./utils/PDF.js";
  import Selectbox from "./Selectbox.svelte";
  import CopyClipBoard from "./CopyClipBoard.svelte";
  import CheckMark from "./CheckMark.svelte";

  const dispatch = createEventDispatcher();
  export let templates;
  export let blog;
  export let user;
  export let chatUsers;
  export let merusToken;
  let selectTemplateView = false;
  let packets = [];
  let packetName = "";
  let packetId = 0;
  let templateIds = [];
  let selectedPacketId = "";
  let selectedPacketData = "";
  let selectedPacketName = "";
  let sharePacketView = false;
  let checkIfEmail = false;
  let sendPDFType = "";
  let shareEmail = "";
  let shareEmailPanel = false;
  let clipboardTxt = "";
  let shareCaseFileId = "";
  let shareUserId = "";
  let shareUserName = "";
  let sending = false;
  let selectedTemplate = "";
  let multiplePdfs = [];
  let sendPDFRes = false;
  let searchKeytemplate = "";
  onMount(async () => {
    getPackets();
  });
  async function getPackets() {
    let body = new URLSearchParams();
    body.append("action", "getPackets");
    body.append("appId", user.appId);
    const req = await fetch(blog.siteurl + "/ajax/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: body.toString(),
    });
    packets = await req.json();
  }
  async function updatePackets() {
    let body = new URLSearchParams();
    body.append("action", "updatePackets");
    body.append("appId", user.appId);
    body.append("packetName", packetName);
    body.append("packetId", packetId);
    body.append("templateIds", JSON.stringify(templateIds));
    const req = await fetch(blog.siteurl + "/ajax/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: body.toString(),
    });
    packets = await req.json();
    selectTemplateView = false;
    dispatch("expand", false);
  }
  async function editPacket(packet) {
    templateIds = JSON.parse(packet.templateIds);
    console.log("templateIds", templateIds);
    packetName = packet.packetName;
    packetId = packet.id;
    templates.forEach((data) => {
      if (templateIds.includes(data.id)) {
        data.selected = true;
      } else {
        data.selected = false;
      }
    });
    selectedTemplate = "";
    selectTemplateView = true;
    dispatch("expand", true);
  }
  async function deletePacket(id) {
    //Display a confirmation alert
    const deleteConfirmed = confirm(
      "Are you sure you want to Delete the Packet?"
    );

    if (deleteConfirmed) {
      let body = new URLSearchParams();
      body.append("action", "deletePacket");
      body.append("id", id);
      body.append("appId", user.appId);
      const req = await fetch(blog.siteurl + "/ajax/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: body.toString(),
      });
      packets = await req.json();
    }
  }
  function displayCreatePopup() {
    templateIds = [];
    packetName = "";
    packetId = 0;
    templates.forEach((data) => {
      data.selected = false;
    });
    selectedTemplate = "";
    selectTemplateView = true;
    dispatch("expand", true);
  }
  function selectTemplate(template) {
    templateIds.push(template.id);
    template.selected = true;
    templates = templates.map((temp, tIndex) =>
      temp.id == template.id ? template : temp
    );
  }
  function unSelectTemplate(template) {
    templateIds = templateIds.filter((id) => id !== template.id);
    template.selected = false;
    templates = templates.map((temp, tIndex) =>
      temp.id == template.id ? template : temp
    );
  }

  function cancelPackets() {
    selectTemplateView = false;
    dispatch("expand", false);
  }
  function selectedPacket(packet) {
    if (selectedPacketId == packet.id) {
      selectedPacketId = "";
      selectedPacketName = "";
      selectedPacketData = "";
      sharePacketView = false;
      dispatch("expand", false);
      dispatch("select", []);
    } else {
      selectedPacketId = packet.id;
      selectedPacketName = packet.packetName;
      selectedPacketData = packet;
      dispatch("select", JSON.parse(packet.templateIds));
    }
  }
  async function sharePacket() {
    sharePacketView = true;
    dispatch("expand", true);
  }

  function checkIfEmailFunc() {
    // Regular expression to check if string is email
    const regexExp =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;

    checkIfEmail = regexExp.test(shareEmail);
  }

  async function handleChatUserSelect(event) {
    shareEmail = event.detail.item.email;
    if (event.detail.item.id != "") {
      shareUserId = event.detail.item.id;
      shareUserName = event.detail.item.fullName;
      shareCaseFileId = event.detail.item.advanced.caseFileIds.toString();
    }
    checkIfEmailFunc();
    console.log("shareEmail", shareEmail);
  }
  async function handleChatUserEnterEmail(event) {
    shareEmail = event.detail.item.email;
    shareUserId = "";
    shareUserName = "";
    shareCaseFileId = "";
    checkIfEmailFunc();
    console.log("enterEmail", shareEmail);
  }

  async function sendSignatureHandle(type) {
    if (type == "link") {
      sendPDFType = "link";
      sendSignature(0);
    } else if (type == "email") {
      sendPDFType = "email";
      sendSignature(0, 1, 1, "", shareEmail);
    }
  }

  async function showShareEmailPanel() {
    shareEmailPanel = true;
    sendPDFType = "email";
  }
  async function getSignatureOptions() {
    let initPageNum = 0;

    let sign1 = "";
    let sign2 = "";
    let sign3 = "";
    let sign4 = "";
    let sign5 = "";
    let sign6 = "";
    let firstPdfData = "";
    let firstAllObjects = "";
    let tempIds = JSON.parse(selectedPacketData.templateIds);
    for (const template of templates) {
      if (tempIds.includes(template.id)) {
        if (selectedTemplate === "") {
          selectedTemplate = template;
        }

        const body = { url: template.pdfUrl, auth: "google" };
        try {
          const res = await fetch(
            `${blog.siteurl}/wp-admin/admin-ajax.php?action=loadTemplateCloudFlare`,
            {
              method: "POST",
              body: JSON.stringify(body),
            }
          );

          const pdfBlob = await res.blob();
          const pdf = await readAsPDF(pdfBlob);
          const pdfName = template.pdfName;
          const numPages = pdf.numPages;
          multiplePdfs.push({
            id: template.id,
            name: template.pdfName,
            pages: initPageNum + numPages,
            url: template.pdfUrl,
          });
          let allObjects = JSON.parse(template.allObjects);
          if (firstAllObjects == "") firstAllObjects = allObjects;
          const pagesScale = Array(numPages).fill(1);

          try {
            const prePdfData = await getData(
              pdfBlob,
              allObjects,
              pdfName,
              pagesScale
            );
            let pdfData = prePdfData[0];
            if (firstPdfData == "") firstPdfData = pdfData;
            let optionData = prePdfData[1];

            for (let i = 0; i < optionData.length; i++) {
              const pageNum = optionData[i].pageIndex + 1 + initPageNum;
              let pageWidth = optionData[i].pageWidth;
              let pageHeight = optionData[i].pageHeight;

              if (pageWidth === 792 && pageHeight === 612) {
                if (
                  optionData[0].pageWidth === 612 &&
                  optionData[0].pageHeight === 792
                ) {
                  pageWidth = 612;
                  pageHeight = 792;
                }
              }

              let posX = optionData[i].x / pageWidth;
              let posY =
                optionData[i].type === "text"
                  ? (optionData[i].y + 15) / pageHeight
                  : optionData[i].y / (pageHeight - 23);

              if (optionData[i].type === "text") {
                if (optionData[i].text === "Initial") {
                  sign2 += sign2
                    ? `,${pageNum}:${posX}:${posY}`
                    : `${pageNum}:${posX}:${posY}`;
                } else if (optionData[i].text === "Date") {
                  sign3 += sign3
                    ? `,${pageNum}:${posX}:${posY}`
                    : `${pageNum}:${posX}:${posY}`;
                } else if (optionData[i].text === "Name") {
                  sign4 += sign4
                    ? `,${pageNum}:${posX}:${posY}`
                    : `${pageNum}:${posX}:${posY}`;
                } else {
                  const text = optionData[i].text
                    .replace(/,/g, "-*-")
                    .replace(/:/g, "_*_");
                  sign5 += sign5
                    ? `,${pageNum}:${posX}:${posY}:${text}`
                    : `${pageNum}:${posX}:${posY}:${text}`;
                }
              } else if (optionData[i].type === "quickSign") {
                const path = optionData[i].url.replace(blog.siteurl, "");
                sign6 += sign6
                  ? `,${pageNum}:${posX}:${posY}:${path}`
                  : `${pageNum}:${posX}:${posY}:${path}`;
              } else if (optionData[i].type === "Signature") {
                sign1 += sign1
                  ? `,${pageNum}:${posX}:${posY}`
                  : `${pageNum}:${posX}:${posY}`;
              }
            }
          } catch (error) {
            console.error("Error processing data:", error);
          }
          initPageNum = initPageNum + numPages;
        } catch (error) {
          console.error("Error fetching template:", error);
        }
      }
    }
    let timestamp = 0;
    let option1 = sign1 + "--*--" + sign3;
    let option2 =
      sign2 +
      "--*--" +
      sign4 +
      "--*--" +
      sign5 +
      "--*--letter--*--" +
      timestamp +
      "--*--" +
      sign6;
    let display_name = "google_" + user.name;
    let body = {
      author_id: user.id,
      author: display_name,
      contact_id: 0,
      pdfData: firstPdfData,
      pdfName: multiplePdfs[0].name,
      option1: option1,
      option2: option2,
      sendPDFType: sendPDFType,
      allObjects: firstAllObjects,
    };
    return body;
  }
  async function sendSignature(
    contact_id = 0,
    cblog = "",
    tw_b_id = 0,
    phone = "",
    email = "",
    cc = ""
  ) {
    if (sending) return;
    sending = true;
    sendPDFRes = false;
    let body = await getSignatureOptions();
    try {
      clipboardTxt = "";
      body.newName = "";
      body.lang = "en";
      body.tw_b_id = tw_b_id;
      body.phone = phone;
      body.email = email;
      body.user_id = shareUserId;
      body.user_name = shareUserName;
      body.user_caseFileId = shareCaseFileId;
      body.cc = cc;
      body.staff_id = user.id;
      body.staff_email = user.email;
      body.appId = user.appId;
      body.merusUserId = selectedTemplate.merusUserId;
      body.merusActivityTypeId = selectedTemplate.activity_type_id;
      body.sharepoint = selectedTemplate.sharepoint;
      body.multiplePdfs = multiplePdfs;
      const req = await fetch(
        blog.siteurl + "/wp-admin/admin-ajax.php?action=updateContactPost",
        {
          method: "POST",
          body: JSON.stringify(body),
        }
      );
      let data = await req.json();
      //alert('Sent successfully!.');
      shareEmail = "";
      shareUserId = "";
      shareUserName = "";
      shareCaseFileId = "";
      sendPDFRes = true;
      if (sendPDFType == "link") {
        clipboardTxt = blog.siteurl + "/s/" + data.id;
      }
    } catch (e) {
      console.log(e);
    } finally {
      sending = false;
    }
  }
  const copy = () => {
    const app = new CopyClipBoard({
      target: document.getElementById("clipboard"),
      props: { clipboardTxt },
    });

    app.$destroy();
    alert("Copied.");
  };
</script>

<div class="packets-wrapper">
  <div style="margin: 10px" class:hidden={!selectTemplateView}>
    <input
      placeholder="Search Template"
      type="text"
      class="flex-grow bg-transparent py-1 px-2 w-full"
      style="border: 1px solid;border-radius: 5px;margin-top: 1em;"
      bind:value={searchKeytemplate}
    />
  </div>
  <div class:hidden={selectTemplateView}>
    <div class="header">
      {#each packets as packet}
        <div
          class="packetItem flex"
          class:selectedPack={packet.id == selectedPacketId}
        >
          <span class="cursor-pointer" on:click={selectedPacket(packet)}
            >{packet.packetName}</span
          >
          <span
            class="px-1 cursor-pointer"
            aria-label="Delete"
            on:click={editPacket(packet)}
          >
            <img
              alt=""
              src="img/edit_temp.svg"
              style="width: 20px; margin-left: 8px;margin-top: 3px;"
            />
          </span>
          <span
            class="px-1 cursor-pointer"
            aria-label="Delete"
            on:click={deletePacket(packet.id)}
          >
            <img
              alt=""
              src="img/delete_temp.svg"
              style="width: 20px;margin-top: 3px;"
            />
          </span>
        </div>
        <div
          class="sharePack cursor-pointer"
          class:hidden={packet.id != selectedPacketId}
          on:click={sharePacket}
        >
          Share
        </div>
      {/each}
      <div
        class="createPack cursor-pointer"
        class:hidden={packets.length > 0}
        on:click={displayCreatePopup}
      >
        Create Packet +
      </div>
      <div
        class="create cursor-pointer"
        class:hidden={packets.length == 0}
        on:click={displayCreatePopup}
      >
        +
      </div>
    </div>
  </div>
  <div class="body">
    {#if selectTemplateView}
      <div class="selectTemplate">
        <div style="padding: 10px;">
          <input
            placeholder="Name the packet..."
            type="text"
            class="flex-grow bg-transparent py-1 px-2 w-100"
            style="border: 1px solid;border-radius: 5px;"
            bind:value={packetName}
          />
          {#if packetName == "" || templateIds.length < 1}
            <button
              class="w-30 text-white font-bold py-1 px-3 md:px-4 mr-3 md:mr-4 rounded bg-gray-500"
              >Save</button
            >
          {:else}
            <button
              class="w-30 text-white font-bold py-1 px-3 md:px-4 mr-3 md:mr-4 rounded bg-blue-500 hover:bg-blue-700"
              on:click={updatePackets}>Save</button
            >
          {/if}
          <button
            class="w-30 text-white font-bold py-1 px-3 md:px-4 mr-3 md:mr-4 rounded bg-blue-500 hover:bg-blue-700"
            on:click={cancelPackets}>Cancel</button
          >
        </div>
        {#each templates as template}
          {#if searchKeytemplate == "" || template.pdfName
              .toLowerCase()
              .includes(searchKeytemplate.toLowerCase())}
            <div class="signItem">
              <div>
                <div>{template.pdfName}</div>
              </div>
              <div class="action w-full flex items-center">
                <div class="flex items-center">
                  {#if template.selected}
                    <span
                      class="select"
                      on:click={() => unSelectTemplate(template)}
                    >
                      Seleted</span
                    >
                  {:else}
                    <span
                      class="select"
                      on:click={() => selectTemplate(template)}
                      style="color:#185ee0;"
                    >
                      Selete Template
                    </span>
                  {/if}
                </div>
              </div>
            </div>
          {/if}
        {/each}
      </div>
    {/if}
    {#if sharePacketView}
      <div class="sharePacket">
        {#if sending}
          <div
            class="flex items-center"
            style="justify-content: space-between; max-width: 200px;"
          >
            <span class:hidden={sendPDFType != "link"}>Generating Link</span>
            <img alt="" src="/loading.gif" style="max-width: 30px;" />
          </div>
        {:else if sendPDFRes}
          {#if sendPDFType != "link"}
            <div
              class="flex items-center"
              style="justify-content: space-between; max-width: 200px;"
            >
              <span>Sent Successfully</span><CheckMark />
            </div>
          {:else}
            <div id="clipboard" />
            <div class="flex items-center" style="justify-content: flex-start;">
              <span>{clipboardTxt}</span>
              <img
                src="filter.svg"
                class="cursor-pointer"
                style="width: 30px; margin-left: 15px;"
                on:click={copy}
                alt="Copy"
              />
            </div>
          {/if}
        {:else if shareEmailPanel}
          <div class="flex">
            <div style="width: calc(100% - 200px);">
              <Selectbox
                items={chatUsers}
                type="email"
                placeholder="Enter Email"
                {merusToken}
                appId={user.appId}
                on:select={handleChatUserSelect}
                on:enterEmail={handleChatUserEnterEmail}
              />
            </div>
            {#if checkIfEmail}
              <button
                class="text-white font-bold py-1 px-3 md:px-4 mr-3 md:mr-4 rounded bg-blue-500 hover:bg-blue-700"
                style="display: flex; align-items: center; margin-left: auto;max-height: 35px;"
                on:click={() => sendSignatureHandle("email")}
              >
                <img
                  alt=""
                  src="mail_w.png"
                  style="width: 25px; margin-right: 15px;max-height: 35px;"
                /> Send</button
              >
            {:else}
              <button
                class="text-white font-bold py-1 px-3 md:px-4 mr-3 md:mr-4 rounded"
                style="display: flex; align-items: center; margin-left: auto; background: grey;max-height: 35px;"
                ><img
                  alt=""
                  src="mail_w.png"
                  style="width: 25px; margin-right: 15px;max-height: 35px;"
                /> Send</button
              >
            {/if}
          </div>
        {:else}
          <div class="p-2 flex" style="gap: 20px;">
            <label
              class="flex items-center h-full hover:bg-gray-500
            cursor-pointer px-2 py-1"
              on:click={() => sendSignatureHandle("link")}
            >
              <img
                alt=""
                src="link.svg"
                style="width: 30px; margin-right: 15px;"
              />
              <span>Link</span>
            </label>
            <label
              class="flex items-center h-full hover:bg-gray-500
            cursor-pointer px-2 py-1"
              on:click={() => showShareEmailPanel()}
            >
              <img
                alt=""
                src="mail.svg"
                style="width: 30px; margin-right: 15px;"
              />
              <span>Email</span>
            </label>
          </div>
        {/if}
      </div>
    {/if}
  </div>
</div>

<style>
  .packets-wrapper .header {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .packets-wrapper .create {
    background: #cbd8e8;
    border-radius: 10px;
    width: 30px;
    height: 30px;
    font-size: 2em;
    line-height: 0.7;
    text-align: center;
  }
  .packets-wrapper .createPack {
    background: #cbd8e8;
    border-radius: 10px;
    width: 140px;
    height: 30px;
    padding: 3px 0;
    text-align: center;
  }
  .packets-wrapper .select {
    color: #333;
  }
  .packets-wrapper .packetItem {
    background: #cbd8e8;
    padding: 3px 10px;
    border-radius: 10px;
    margin-right: 10px;
  }
  .packets-wrapper .packetItem.selectedPack {
    background: #185ee0;
    color: #fff;
  }
  .packets-wrapper .sharePack {
    background: #185ee0;
    color: #fff;
    padding: 3px 10px;
    border-radius: 10px;
    margin-right: 10px;
  }
  .packets-wrapper .sharePacket {
    min-height: 230px;
    padding: 10px;
  }
</style>
